import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Paginate } from '../../../../shared/models/pagination.model';
import { ResponseDto } from '../../../../shared/models/response-dto.model';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../../shared/utils/pagination.utils';
import { ContextDefinition } from '../models/context-definition.model';
import { CreateContextDefinitionPayload } from '../models/create-context-definition-payload.model';
import { DocumentType } from '../models/document-type.model';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private readonly http = inject(HttpClient);

  getContextDefinitions(page = DEFAULT_PAGE, search?: string): Observable<Paginate<ContextDefinition>> {
    let params = new HttpParams().set('page', page).set('pageSize', DEFAULT_PAGE_SIZE);

    if (search) {
      params = params.set('query', search);
    }

    return this.http
      .get<ResponseDto<Paginate<ContextDefinition>>>(`/management-context`, { params })
      .pipe(map((response) => response.data));
  }

  createContextDefinition(payload: CreateContextDefinitionPayload): Observable<void> {
    return this.http.post<void>(`/management-context`, payload);
  }

  updateContextDefinition(id: string, payload: CreateContextDefinitionPayload): Observable<void> {
    return this.http.patch<void>(`/management-context/${id}`, payload);
  }

  deleteContextDefinition(id: string): Observable<void> {
    return this.http.delete<void>(`/management-context/${id}`);
  }

  getDocumentTypes(page = DEFAULT_PAGE, search?: string): Observable<Paginate<DocumentType>> {
    let params = new HttpParams().set('page', page).set('pageSize', DEFAULT_PAGE_SIZE);

    if (search) {
      params = params.set('query', search);
    }

    return this.http
      .get<ResponseDto<Paginate<DocumentType>>>(`/management-document-type`, { params })
      .pipe(map((response) => response.data));
  }
}
