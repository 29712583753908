import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { PromptLibraryEffects } from './+state/prompt-library.effects';
import { promptLibraryFeature } from './+state/prompt-library.reducer';

export function providePromptLibrary(): EnvironmentProviders {
  return makeEnvironmentProviders([provideState(promptLibraryFeature), provideEffects([PromptLibraryEffects])]);
}
