import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { ContextLibraryEffects } from './+state/context-library.effects';
import { contextLibraryFeature } from './+state/context-library.reducer';

export function provideContextLibrary(): EnvironmentProviders {
  return makeEnvironmentProviders([provideState(contextLibraryFeature), provideEffects([ContextLibraryEffects])]);
}
