import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, mergeMap, Observable, of } from 'rxjs';
import { PlatformManagerService } from '../../../../shared/data/data-access-platform-manager/service/platform-manager.service';
import { ResponseDto } from '../../../../shared/models/response-dto.model';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../../shared/utils/pagination.utils';
import { PromptGroup } from '../enums/prompt-group.enum';
import { CreatePromptRequest } from '../models/create-prompt-request.model';
import { Prompt } from '../models/prompt.model';
import { Paginate } from './../../../../shared/models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class PromptService {
  private readonly http = inject(HttpClient);
  private readonly platformManagerService = inject(PlatformManagerService);

  private tenantCache = new Map<string, string>();

  getEnrichedPrompts(groupType?: PromptGroup, page = DEFAULT_PAGE, search?: string): Observable<Paginate<Prompt>> {
    if (page === DEFAULT_PAGE) {
      this.tenantCache = new Map<string, string>();
    }

    return this.getPrompts(groupType, page, search).pipe(
      mergeMap((paginateResponse) => {
        const prompts = paginateResponse.items;

        const uncachedTenantIds = Array.from(
          new Set(
            prompts
              .filter((prompt) => !!prompt.tenant)
              .map((prompt) => prompt.tenant)
              .filter((tenantId) => !this.tenantCache.has(tenantId))
          )
        );

        if (uncachedTenantIds.length === 0) {
          // If all tenants are cached, return enriched paginated response directly
          return of(this.enrichPromptsWithCache(paginateResponse));
        }

        // Fetch uncached tenant details
        return this.platformManagerService.getTenantsByIdentifiers(uncachedTenantIds).pipe(
          map((tenantDetails) => {
            // Add fetched tenants to the cache
            tenantDetails.forEach((tenant) => this.tenantCache.set(tenant.tenant, tenant.companyName));

            // Return enriched paginated response
            return this.enrichPromptsWithCache(paginateResponse);
          })
        );
      })
    );
  }

  private enrichPromptsWithCache(paginateResponse: Paginate<Prompt>): Paginate<Prompt> {
    return {
      ...paginateResponse,
      items: paginateResponse.items.map((prompt) => {
        if (prompt.tenant) {
          return {
            ...prompt,
            tenantName: this.tenantCache.get(prompt.tenant) || '-',
          };
        }

        return prompt;
      }),
    };
  }

  private getPrompts(groupType?: PromptGroup, page = DEFAULT_PAGE, search?: string): Observable<Paginate<Prompt>> {
    let params = new HttpParams().set('page', page).set('pageSize', DEFAULT_PAGE_SIZE);

    if (groupType) {
      params = params.set('groupType', groupType);
    }

    if (search) {
      params = params.set('search', search);
    }

    return this.http.get<ResponseDto<Paginate<Prompt>>>(`/prompts`, { params }).pipe(map((response) => response.data));
  }

  getPromptById(id: string): Observable<Prompt> {
    return this.http.get<ResponseDto<Prompt>>(`/prompts/${id}`).pipe(map((response) => response.data));
  }

  updatePromptById(id: string, payload: Partial<CreatePromptRequest>): Observable<void> {
    return this.http.patch<void>(`/prompts/${id}`, payload);
  }

  createPrompt(payload: CreatePromptRequest): Observable<void> {
    return this.http.post<void>(`/prompts`, payload);
  }

  deletePromptById(id: string): Observable<void> {
    return this.http.delete<void>(`/prompts/${id}`);
  }
}
