import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, tap } from 'rxjs';

import { Router } from '@angular/router';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { AlertService } from '../../../../core/ui-kit/services/alert.service';
import { Paginate } from '../../../../shared/models/pagination.model';
import { Operator } from '../models/operator.model';
import { OperatorService } from '../service/operator.service';
import { loadOperatorActions } from './operator.actions';
import { selectOperatorsAllPagesLoaded } from './operator.selectors';

@Injectable()
export class OperatorEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly operatorService = inject(OperatorService);
  private readonly router = inject(Router);
  private readonly alertService = inject(AlertService);

  loadOperators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOperatorActions.loadOperators),
      concatLatestFrom(() => [this.store.select(selectOperatorsAllPagesLoaded)]),
      filter(([{ infiniteScroll }, allPagesLoaded]) => !infiniteScroll || !allPagesLoaded),
      map(([loadOperatorsAction]) => loadOperatorsAction),
      switchMap(({ page, infiniteScroll, query }) =>
        this.operatorService.getOperators(page, undefined, query).pipe(
          map((operators: Paginate<Operator>) =>
            loadOperatorActions.loadOperatorsSuccess({
              operators,
              infiniteScroll,
            })
          )
        )
      )
    )
  );

  loadOperatorById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOperatorActions.loadOperatorByID),
      switchMap(({ id }) =>
        this.operatorService.getOperatorById(id).pipe(
          map((operator: Operator) =>
            loadOperatorActions.loadOperatorByIDSuccess({
              operator,
            })
          )
        )
      )
    )
  );

  updateOperatorById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOperatorActions.updateOperatorByID),
      switchMap(({ id, payload }) =>
        this.operatorService.updateOperatorById(id, payload).pipe(
          map((operator: Operator) =>
            loadOperatorActions.loadOperatorByIDSuccess({
              operator,
            })
          )
        )
      )
    )
  );

  deleteOperatorById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadOperatorActions.deleteOperatorByID),
        switchMap(({ id }) =>
          this.operatorService.deleteOperatorById(id).pipe(
            tap(() => {
              this.router.navigate(['/operators']);
              this.alertService.showOperationCompletedAlert('user.reactivation.card.desc', 4000);
            })
          )
        )
      ),
    { dispatch: false }
  );

  reactivateOperatorById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadOperatorActions.reactivateOperatorByID),
        switchMap(({ id }) =>
          this.operatorService.reactivateOperatorById(id).pipe(
            tap(() => {
              this.router.navigate(['/operators']);
              this.alertService.showOperationCompletedAlert();
            })
          )
        )
      ),
    { dispatch: false }
  );

  createOperator$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadOperatorActions.createOperator),
        switchMap(({ payload }) =>
          this.operatorService.createOperator(payload).pipe(
            tap((operator) => {
              this.router.navigate(['/operators', operator.id]);
              this.alertService.showOperationCompletedAlert();
            })
          )
        )
      ),
    { dispatch: false }
  );

  sendResetPasswordLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadOperatorActions.sendResetPasswordLink),
        switchMap(({ email }) =>
          this.operatorService.sendResetPasswordLink(email).pipe(
            tap(() => {
              this.alertService.showOperationCompletedAlert();
            })
          )
        )
      ),
    { dispatch: false }
  );

  resetOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOperatorActions.resetOtp),
      switchMap(({ id, email }) =>
        this.operatorService.resetOtp(id).pipe(map(() => loadOperatorActions.sendResetPasswordLink({ email })))
      )
    )
  );
}
