import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs';

import { Router } from '@angular/router';
import { AlertService } from '../../../../core/ui-kit/services/alert.service';
import { Paginate } from '../../../../shared/models/pagination.model';
import { Prompt } from '../models/prompt.model';
import { PromptService } from '../service/prompt.service';
import { loadPromptLibraryActions } from './prompt-library.actions';

@Injectable()
export class PromptLibraryEffects {
  private readonly actions$ = inject(Actions);
  private readonly promptService = inject(PromptService);
  private readonly alertService = inject(AlertService);
  private readonly router = inject(Router);

  loadPrompts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPromptLibraryActions.loadPrompts),
      switchMap(({ page, group, query }) =>
        this.promptService.getEnrichedPrompts(group, page, query).pipe(
          map((prompts: Paginate<Prompt>) =>
            loadPromptLibraryActions.loadPromptsSuccess({
              prompts,
            })
          )
        )
      )
    )
  );

  loadPromptById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPromptLibraryActions.loadPromptById),
      switchMap(({ id }) =>
        this.promptService.getPromptById(id).pipe(
          map((prompt: Prompt) =>
            loadPromptLibraryActions.loadPromptByIdSuccess({
              prompt,
            })
          )
        )
      )
    )
  );

  createPrompt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadPromptLibraryActions.createPrompt),
        switchMap(({ payload }) =>
          this.promptService.createPrompt(payload).pipe(
            tap(() => {
              this.alertService.showOperationCompletedAlert();
              this.router.navigate(['/prompt-library']);
            })
          )
        )
      ),
    { dispatch: false }
  );

  updatePromptById$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadPromptLibraryActions.updatePromptById),
        switchMap(({ id, payload }) =>
          this.promptService.updatePromptById(id, payload).pipe(
            tap(() => {
              this.alertService.showOperationCompletedAlert();
            })
          )
        )
      ),
    { dispatch: false }
  );

  deletePromptById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPromptLibraryActions.deletePromptById),
      switchMap(({ id }) =>
        this.promptService.deletePromptById(id).pipe(
          tap(() => {
            this.alertService.showOperationCompletedAlert();
          }),
          map(() => loadPromptLibraryActions.loadPrompts({}))
        )
      )
    )
  );
}
