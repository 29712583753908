import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs';

import { AlertService } from '../../../../core/ui-kit/services/alert.service';
import { Paginate } from '../../../../shared/models/pagination.model';
import { DEFAULT_PAGE } from '../../../../shared/utils/pagination.utils';
import { ContextDefinition } from '../models/context-definition.model';
import { DocumentType } from '../models/document-type.model';
import { ContextService } from '../service/context.service';
import { loadContextLibraryActions } from './context-library.actions';

@Injectable()
export class ContextLibraryEffects {
  private readonly actions$ = inject(Actions);
  private readonly contextService = inject(ContextService);
  private readonly alertService = inject(AlertService);

  loadContextDefinitions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContextLibraryActions.loadContextDefinitions),
      switchMap(({ page, search }) =>
        this.contextService.getContextDefinitions(page, search).pipe(
          map((contextDefinitions: Paginate<ContextDefinition>) =>
            loadContextLibraryActions.loadContextDefinitionsSuccess({
              contextDefinitions,
            })
          )
        )
      )
    )
  );

  createContextDefinition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContextLibraryActions.createContextDefinition),
      switchMap(({ payload }) =>
        this.contextService.createContextDefinition(payload).pipe(
          tap(() => {
            this.alertService.showOperationCompletedAlert();
          }),
          map(() => loadContextLibraryActions.loadContextDefinitions({ page: DEFAULT_PAGE }))
        )
      )
    )
  );

  updateContextDefinition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContextLibraryActions.updateContextDefinition),
      switchMap(({ id, payload }) =>
        this.contextService.updateContextDefinition(id, payload).pipe(
          tap(() => {
            this.alertService.showOperationCompletedAlert();
          }),
          map(() => loadContextLibraryActions.loadContextDefinitions({ page: DEFAULT_PAGE }))
        )
      )
    )
  );

  deleteContextDefinition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContextLibraryActions.deleteContextDefinition),
      switchMap(({ id }) =>
        this.contextService.deleteContextDefinition(id).pipe(
          tap(() => {
            this.alertService.showOperationCompletedAlert();
          }),
          map(() => loadContextLibraryActions.loadContextDefinitions({ page: DEFAULT_PAGE }))
        )
      )
    )
  );

  loadDocumentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadContextLibraryActions.loadDocumentTypes),
      switchMap(({ page, search }) =>
        this.contextService.getDocumentTypes(page, search).pipe(
          map((documentTypes: Paginate<DocumentType>) =>
            loadContextLibraryActions.loadDocumentTypesSuccess({
              documentTypes,
            })
          )
        )
      )
    )
  );
}
