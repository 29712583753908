import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs';
import { IUser } from '../../../../features/authentication/models/user.model';
import { Paginate } from '../../../models/pagination.model';
import { LicenseTemplate, LicenseTemplateComplete } from '../models/license-template.model';
import { TenantLicense } from '../models/tenant-license.model';
import { Tenant } from '../models/tenant.model';
import { Vertical } from '../models/vertical.model';
import { PlatformManagerService } from '../service/platform-manager.service';
import { platformManagerActions } from './platform-manager.actions';

@Injectable()
export class PlatformManagerEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly platfromManagerService = inject(PlatformManagerService);

  getTenants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(platformManagerActions.getTenants),
      switchMap(({ page, pageSize, isInfiniteScroll }) =>
        this.platfromManagerService.getTenants(page, pageSize).pipe(
          map((tenants: Paginate<Tenant>) =>
            platformManagerActions.getTenantsSuccess({
              tenants,
              isInfiniteScroll,
            })
          )
        )
      )
    )
  );

  loadTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(platformManagerActions.loadTenant),
      switchMap(({ tenant }) =>
        this.platfromManagerService.loadTenant(tenant).pipe(
          map((tenantRes: Tenant) =>
            platformManagerActions.loadTenantSuccess({
              tenant: tenantRes,
            })
          )
        )
      )
    )
  );

  updateTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(platformManagerActions.updateTenantByTenantKey),
      switchMap(({ id, payload }) =>
        this.platfromManagerService
          .updateTenant(id, payload)
          .pipe(map((tenant) => platformManagerActions.loadTenantSuccess({ tenant })))
      )
    )
  );

  getTenantUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(platformManagerActions.getTenantUsers),
      switchMap(({ page, pageSize, tenant, role }) =>
        this.platfromManagerService.getTenantUsers(page, pageSize, tenant, role).pipe(
          map((users: Paginate<IUser>) =>
            platformManagerActions.getTenantUsersSuccess({
              users,
            })
          )
        )
      )
    )
  );

  getTenantLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(platformManagerActions.getTenantLicense),
      switchMap(({ tenant }) =>
        this.platfromManagerService.getTenantLicense(tenant).pipe(
          map((tenantLicense: TenantLicense) =>
            platformManagerActions.getTenantLicenseSuccess({
              tenantLicense,
            })
          )
        )
      )
    )
  );

  getVerticals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(platformManagerActions.getVerticals),
      switchMap(({ page, pageSize }) =>
        this.platfromManagerService.getVerticals(page, pageSize).pipe(
          map((verticals: Paginate<Vertical>) =>
            platformManagerActions.getVerticalsSuccess({
              verticals,
            })
          )
        )
      )
    )
  );

  getVertical$ = createEffect(() =>
    this.actions$.pipe(
      ofType(platformManagerActions.getVertical),
      switchMap(({ vertical }) =>
        this.platfromManagerService.getVertical(vertical).pipe(
          map((vertical: Vertical) =>
            platformManagerActions.getVerticalSuccess({
              vertical,
            })
          )
        )
      )
    )
  );

  getLicenseTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(platformManagerActions.getLicenseTemplates),
      switchMap(({ page, pageSize }) =>
        this.platfromManagerService.getLicenseTemplates(page, pageSize).pipe(
          map((licenseTemplates: Paginate<LicenseTemplate>) =>
            platformManagerActions.getLicenseTemplatesSuccess({
              licenseTemplates,
            })
          )
        )
      )
    )
  );

  getLicenseTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(platformManagerActions.getLicenseTemplate),
      switchMap(({ licenseTemplate }) =>
        this.platfromManagerService.getLicenseTemplate(licenseTemplate).pipe(
          map((licenseTemplate: LicenseTemplateComplete) =>
            platformManagerActions.getLicenseTemplateSuccess({
              licenseTemplate,
            })
          )
        )
      )
    )
  );
}
